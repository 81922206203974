// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-energy-storage-page-js": () => import("./../../../src/templates/energy-storage-page.js" /* webpackChunkName: "component---src-templates-energy-storage-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-ground-page-js": () => import("./../../../src/templates/ground-page.js" /* webpackChunkName: "component---src-templates-ground-page-js" */),
  "component---src-templates-incentive-programs-page-js": () => import("./../../../src/templates/incentive-programs-page.js" /* webpackChunkName: "component---src-templates-incentive-programs-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-payment-options-page-js": () => import("./../../../src/templates/payment-options-page.js" /* webpackChunkName: "component---src-templates-payment-options-page-js" */),
  "component---src-templates-residential-commercial-page-js": () => import("./../../../src/templates/residential-commercial-page.js" /* webpackChunkName: "component---src-templates-residential-commercial-page-js" */),
  "component---src-templates-wind-page-js": () => import("./../../../src/templates/wind-page.js" /* webpackChunkName: "component---src-templates-wind-page-js" */)
}

